<template>
    <HomeView />
</template>

<script>
    import HomeView from '@/views/HomeView.vue';
    export default {
        name: 'HomeLayout',
        components:{
            HomeView
        }
    }
</script>