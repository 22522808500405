<template>
    <LoginView />
</template>

<script>
    import LoginView from '@/views/LoginView.vue';
    export default {
        name: 'HomeLayout',
        components:{
            LoginView
        }
    }
</script>