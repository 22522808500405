<template>
    <CreatePollView />
</template>

<script>
    import CreatePollView from '@/views/CreatePollView.vue';
    export default {
        name: 'CreatePollLayout',
        components:{
            CreatePollView
        }
    }
</script>