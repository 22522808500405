<template>
    <MetricsView />
</template>

<script>
    import MetricsView from '@/views/MetricsView.vue';
    export default {
        name: 'MetricsLayout',
        components:{
            MetricsView
        }
    }
</script>